import clsx from 'clsx';
import { labelTag } from './label-tag.css.ts';
import { ComponentPropsWithoutRef, FC } from 'react';

export const LabelTag: FC<ComponentPropsWithoutRef<'div'>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={clsx(labelTag, className)} {...rest}>
      {children}
    </div>
  );
};
